export const API_URL = 'https://order.ellaapp.com/admin/web/v1/';
export const API_URL2 = 'https://order.ellaapp.com/admin/web/';
export const LOCAL_PAYMENT_API_URL = "https://192.168.10.11";
export const LOCAL_PAYMENT_PORT = "82";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "https://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://wcsmobilekiosk.ellaapp.com/kioskautologin/6256d0d4206c4f25e65fdb372218c645/671b260231d8c59344896401119215c1";
export const page_redirection_time = 30;
export const auto_logout_timer = 7;
export const timer_time = 30;
export const payment_failed_modal=30;
export const apitimeout=10;
